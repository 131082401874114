import { School, Work } from '@material-ui/icons';
import React from 'react';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
const Experience = () => {
  return (
    <VerticalTimeline lineColor='#3e497a'>
      <VerticalTimelineElement
        className='vertical-timeline-element--education'
        date='2010-2012'
        iconStyle={{ background: '#3e497a', color: '#fff' }}
        icon={<School />}
      >
        <h3>Council Of Higher Secondary Education, Bhubaneswar, Odisha</h3>
        <p>+2 , Satyabadi H S School, Kalapathar</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className='vertical-timeline-element--education'
        date='2015-2017'
        iconStyle={{ background: '#3e497a', color: '#fff' }}
        icon={<School />}
      >
        <h3>Sambalpur University, Odisha</h3>
        <p>BCA</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className='vertical-timeline-element--education'
        date='2010-2012'
        iconStyle={{ background: '#e9d35b', color: '#fff' }}
        icon={<Work />}
      >
        <h3>Ionox Software Pvt. Ltd., Bhubaneswar</h3>
        <p>Associate Software Engineer`</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className='vertical-timeline-element--education'
        date='October 2019 - October 2020'
        iconStyle={{ background: '#e9d35b', color: '#fff' }}
        icon={<Work />}
      >
        <h3>Dell EMC</h3>
        <p>Software Engineer</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className='vertical-timeline-element--education'
        date='March 2021 - January 2022'
        iconStyle={{ background: '#e9d35b', color: '#fff' }}
        icon={<Work />}
      >
        <h3>DMI Innovations Pvt Ltd,</h3>
        <p>Software Engineer</p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
};

export default Experience;

import { GitHub } from '@material-ui/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ProjectList } from '../helpers/ProjectList';
import '../styles/ProjectDisplay.css';

const ProjectDisplay = () => {
  const { id } = useParams();
  const { name, image, skills } = ProjectList[id];
  return (
    <div className='project'>
      <h1>{name}</h1>
      <img src={image} alt={name} />
      <p>
        <b>{skills}</b>
      </p>
      <GitHub />
    </div>
  );
};

export default ProjectDisplay;

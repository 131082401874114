import { Instagram, Facebook, GitHub, Twitter } from '@material-ui/icons';
import React from 'react';
import '../styles/Footer.css';
const Footer = () => {
  return (
    <div className='footer'>
      <div className='socialMedia'>
        <Instagram />
        <Twitter />
        <Facebook />
        <GitHub />
        <p> &copy; 2022 thedevsaroj.com</p>
      </div>
    </div>
  );
};

export default Footer;
